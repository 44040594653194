<template>
  <div class="footer">
    <div class="footerTop">链接:</div>
    <div style="border-bottom: 1px solid #4a4b4c"></div>
    <div
      class="flex"
      style="margin: 50px 100px 0 100px; justify-content: space-between"
    >
      <div>
        <div class="flex" style="color: #999999; margin-left: 50px;font-size: 10px">
          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">售后服务</div>
          <div style="margin-right: 30px; cursor: pointer">|</div>
          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">投诉建议</div>
          <div style="margin-right: 30px; cursor: pointer" >|</div>
<!--          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">收费服务协议</div>-->
<!--          <div style="margin-right: 30px; cursor: pointer">|</div>-->
<!--          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">学籍注册协议</div>-->
<!--          <div style="margin-right: 30px; cursor: pointer">|</div>-->
          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">关于我们</div>
          <div style="margin-right: 30px; cursor: pointer">|</div>
          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">用户服务协议</div>
          <div style="margin-right: 30px; cursor: pointer">|</div>
          <div style="margin-right: 30px; cursor: pointer" @click="handleToArticle">查看更多</div>
        </div>
        <div class="flex" style="margin: 30px 300px 30px 0">
<!--          <img-->
<!--            src="https://www.haixingkt.com/Public/Uploads/image/20201207/20201207173310_82269.png"-->
<!--            alt=""-->
<!--            style="width: 20px; height: 20px"-->
<!--          />-->
          <div>
<!--            <div style="margin-left: 10px; color: #ffffff">-->
<!--              鄂公网安备-->
<!--              <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"-->
<!--              >鄂ICP备2023030661号-1</a-->
<!--              >-->
<!--            </div>-->
            <div style="margin-left: 10px; color: #ffffff">
              鄂公网安备
              <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"
              >鄂公网安备42090202000645号</a
              >

            </div>
            <div style="margin-left: 10px; color: #ffffff">

              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
              >鄂ICP备2023030661号-1
              </a
              >

            </div>
          </div>


        </div>

      </div>
      <div
        class="flex"
        style="flex-direction: column; text-algin: center; padding-bottom: 10px"
      >
        <h4
          style="
            color: #999999;
            text-algin: center;
            margin-left: 10px;
            margin-bottom: 5px;
          "
        >
         扫描公众号二维码
        </h4>
        <img
          src="http://qiniu.pengfkt.com/FjF_4cnjhKgtIxOM97DYwvkii1KM"
          alt=""
          style="width: 100px; height: 100px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleToArticle() {
      this.$router.push("/article")
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #2b2b38;
}
.footerTop {
  height: 80px;
  margin-left: 150px;
  line-height: 80px;
  color: #999;
}
a {
  color: #fff;
  text-decoration: none;
}
</style>
